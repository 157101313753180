// responsive
@mixin responsive($type) {
    @if $type == "sm" {
        @media screen and (min-width: 576px) {
            @content;
        }
    }
    @if $type == "md" {
        @media screen and (min-width: 768px) {
            @content;
        }
        // @media screen and (min-width: 992px) {
        //     @content;
        // }
    }
    @if $type == "lg" {
        @media screen and (min-width: 992px) {
            @content;
        }
    }
    @if $type == "xl" {
        @media screen and (min-width: 1200px) {
            @content;
        }
    }
    @if $type == "xxl" {
        // @media screen and (min-width: 1400px){
        //     @content;
        // }
        @media screen and (min-width: 1440px) {
            @content;
        }
    }
    @if $type == "sm-max" {
        @media screen and (max-width: 575px) {
            @content;
        }
    }
    @if $type == "md-max" {
        @media screen and (max-width: 767px) {
            @content;
        }
        // @media screen and (max-width: 992px) {
        //     @content;
        // }
    }
    @if $type == "lg-max" {
        @media screen and (max-width: 991px) {
            @content;
        }
    }
    @if $type == "xl-max" {
        @media screen and (max-width: 1199px) {
            @content;
        }
    }
    @if $type == "xxl-max" {
        @media screen and (max-width: 1399px) {
            @content;
        }
    }
    @if $type == "sm-only" {
        @media (min-width: 576px) and (max-width: 767px) {
            @content;
        }
    }
    @if $type == "md-only" {
        @media (min-width: 768px) and (max-width: 991px) {
            @content;
        }
    }
    @if $type == "lg-only" {
        @media (min-width: 992px) and (max-width: 1199px) {
            @content;
        }
    }
    @if $type == "xl-only" {
        @media (min-width: 1200px) and (max-width: 1399px) {
            @content;
        }
    }
}