@charset "utf-8";
@use "./settings" as variable;
@use "./tools/mixin" as mixin;

// calcを計算式のままコンパイルするための変数
$calcSp: 750;
$calcPc: 1200;
$calcXxl: 1440;
$calcPcMax: 1920;

/* =============================================================
    TOP Page 
============================================================= */

.home-contents {
  @include mixin.responsive("md-max") {
    padding-top: calc(210 * (100vw / #{$calcSp}));
  }
  @media (orientation: landscape) {
   padding-top: 0;;
  }
}

/**
 * 各ブランドコンテンツ
 */
 .home-contents {
  .core-contents {
    display: none;
    &.show {
      display: block;
    }
    &--lucien {
      background-color: var(--color-bg-light);
    }
    &--lpfg {
      background-color: var(--color-bg-light);
    }
    &--paris {
      background-color: var(--color-bg-light);
    }
  }
}
/**
 * core-contents__tab （ブランド切り替え タブ）
 */
.home-contents {
  .core-contents {
    &__tab {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: calc(18 * (100vw / #{$calcSp}));
      margin: 0 auto;
      padding: calc(62 * (100vw / #{$calcSp})) 0 calc(34 * (100vw / #{$calcSp}));
      background-color: var(--color-bg);
      @include mixin.responsive("md") {
        gap: calc(25 * (100vw / #{$calcPc}));
        padding: calc(43 * (100vw / #{$calcPc})) 0 calc(24 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        gap: 25px;
        padding: 43px 0 24px;
      }
      &--item {
        padding: calc(24 * (100vw / #{$calcSp})) 0 calc(20 * (100vw / #{$calcSp}));
        box-shadow: 0px 0px calc(10 * (100vw / #{$calcSp})) rgba(0, 0, 0, 0.1);
        border-radius: calc(6 * (100vw / #{$calcSp}));
        font-family: var(--font-en);
        text-align: center;
        font-size: calc(28 * (100vw / #{$calcSp}));
        line-height: 1;
        letter-spacing: 0.15em;
        background: var(--color-alpha);
        @include mixin.responsive("md") {
          padding: calc(12 * (100vw / 1200)) 0 calc(11 * (100vw / 1200));
          box-shadow: 0px 0px calc(6 * (100vw / #{$calcPc})) rgba(0, 0, 0, 0.1);
          border-radius: calc(3 * (100vw / #{$calcPc}));
          font-size: calc(20 * (100vw / #{$calcPc}));
          cursor: pointer;
          transition: var(--transition-default);
        }
        @include mixin.responsive("xl") {
          padding: 12px 0 11px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          font-size: 20px;
        }
        &:hover {
          @include mixin.responsive("md") {
            opacity: var(--hover-opacity);
          }
        }
      }
      &--lucien {
        width: calc(670 * (100vw / #{$calcSp}));
        color: var(--color-gray-middle);
        @include mixin.responsive("md") {
          width: calc(330 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          width: 330px;
        }
        &.active {
          background-color: var(--color-gray);
          box-shadow: none;
        }
      }
      &--lpfg {
        width: calc(326 * (100vw / #{$calcSp}));
        @include mixin.responsive("md") {
          width: calc(330 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          width: 330px;
        }
          &.active {
            background-color: var(--color-dark);
            color: var(--color-gray-light-middle);
            box-shadow: none;
          }
        .red{
          color: var(--color-red);
        }
      }
      &--paris {
        width: calc(326 * (100vw / #{$calcSp}));
        color: var(--color-dark);
        @include mixin.responsive("md") {
          width: calc(330 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          width: 330px;
        }
        &.active {
          background-color: var(--color-dark);
          color: var(--color-white);
          box-shadow: none;
        }
      }
    }
  }
}

/**
 * core-contents__movie （Vimeo）
 */
.home-contents {
  .core-contents {
    &__movie {
      &--lucien {
        /** lucien pellat-finetの動画の背景 */
        // aspect-ratio: 16 / 9;
        // background-size: 100%;
        // background-image: url(../../assets/img/home/movie/lpf_movie_sp.jpg);
        // background-repeat: no-repeat;
        // @include mixin.responsive("md") {
        //   aspect-ratio: 16 / 9;
        //   background-size: 100%;
        //   background-image: url(../../assets/img/home/movie/lpf_movie_pc.jpg);
        // }
      }
      &--lpfg {
        /** LPFGの動画の背景 */
        /* 
        aspect-ratio: 16 / 9;
        background-size: 100%;
        background-image: url(../../assets/img/home/movie/lpfg_movie.jpg);
        */
      }
      &--paris {
        /** LPF PARISの動画の背景 */
        /* 
        aspect-ratio: 16 / 9;
        background-size: 100%;
        background-image: url(../../assets/img/home/movie/paris_movie.jpg);
         */
      }
      &--outer {
        position:relative;
        padding:56.25% 0 0 0;
      }
      &--item{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
    }
  }
}

/**
 * core-contents__mv （メインビジュアル splide カルーセル）
 */
.home-contents {
  .core-contents {
    &__mv {
      position: relative;
      .splide {
        &__list  {
          height: auto;
        }
        &__slide{
          img {
           height: auto;
           width: 100%;
         }
        }
        &__pagination {
          display: flex;
          gap: calc(16 * (100vw / #{$calcSp}));
          position: absolute;
          left: 0;
          right: 0;
          bottom: calc(30 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            gap: calc(10 * (100vw / #{$calcPc}));
            bottom: calc(40 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            gap: 10px;
            bottom: 40px;
          }
          .splide__pagination__page {
            display: block;
            width: calc(36 * (100vw / #{$calcSp}));
            height: calc(6 * (100vw / #{$calcSp}));
            border-radius: 0;
            appearance: none;
            border: 0;
            border-radius: 0;
            background-color: var(--color-black-light);
            background: #ddd;
            @include mixin.responsive("md") {
              width: calc(30 * (100vw / #{$calcPc}));
              height: calc(4 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              width: 30px;
              height: 4px;
            }
            &.is-active {
              background-color: var(--color-black-dark);
            }
          }
        }
      }
    }
  }
}

/**
 * core-contents__ttl / core-contents__txt
 *（セクションの見出し、リード文の基本設定。セクション固有のものは、あとで以降で個別に上書きする）
 */
.home-contents {
  .core-contents {
    &__ttl {
      margin-bottom: calc(38 * (100vw / #{$calcSp}));
      text-transform: uppercase;
      font-family: var(--font-en);
      font-size: calc(44 * (100vw / #{$calcSp}));
      line-height: 1.18;
      letter-spacing: 0.15em;
      text-indent: 0.15em;
      text-align: center;
      color: var(--color-black);
      @include mixin.responsive("md") {
        margin-bottom: calc(32 * (100vw / #{$calcPc}));
        font-size: calc(36 * (100vw / #{$calcPc}));
        line-height: 1;
      }
      @include mixin.responsive("xl") {
        margin-bottom:32px;
        font-size: 36px;
        line-height: 1;
      }
    }
    &__txt {
      font-size: calc(24 * (100vw / #{$calcSp}));
      line-height: 2;
      letter-spacing: 0.15em;
      text-indent: 0.15em;
      text-align: center;
      color: var(--color-black);
      @include mixin.responsive("md") {
        font-size: calc(14 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        font-size: 14px;
      }
      &--paragraph {
        margin-bottom: calc(26 * (100vw / #{$calcSp}));
        @include mixin.responsive("md") {
          margin-bottom: calc(24 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          margin-bottom: 24px;
        }
        &:first-child {
          margin-bottom: calc(26 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            margin-bottom: calc(16 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

/**
 * .contents__btn （ボタン）
 */
 .home-contents {
  .contents__btn--outer {
    width: calc(356 * (100vw / #{$calcSp}));
    height: calc(60 * (100vw / #{$calcSp}));
    margin: 0 auto;
    @include mixin.responsive("md") {
      width: calc(200 * (100vw / #{$calcPc}));
      height: calc(40 * (100vw / #{$calcPc}));
      transition: var(--transition-default);
    }
    @include mixin.responsive("xl") {
      width: 200px;
      height: 40px;
    }
    .contents__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-black-dark);
      border-radius: 0;
      width: 100%;
      height: 100%;
      font-size: calc(24 * (100vw / #{$calcSp}));
      letter-spacing: 0.15em;
      text-indent: 0.15em;
      line-height: 1;
      font-family: var(--font-en);
      color: var(--color-white);
      background-color: var(--color-black-dark);
      @include mixin.responsive("md") {
        transition: var(--transition-default);
        font-size: calc(14 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        font-size: 14px;
      }
      &:hover {
        @include mixin.responsive("md") {
          color: var(--color-black-dark);
          background-color: var(--color-white);
        }
      }
    }
    &--history {
      position: relative;
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(58 * (100vw / #{$calcSp}));
        margin: auto;
        width: calc(12 * (100vw / #{$calcSp}));
        height: calc(2 * (100vw / #{$calcSp}));
        background-color: var(--color-white);
        @include mixin.responsive("md") {
          right: calc(28 * (100vw / #{$calcPc}));
          width: calc(6 * (100vw / #{$calcPc}));
          height: 1px;
        }
        @include mixin.responsive("xl") {
          right: 28px;
          width: 6px;
        }
      }
      &::after {
        transform: rotate(90deg);
      }
      .contents__btn {
        text-indent: -1em;
        cursor: pointer;
        text-transform: uppercase;
      }
      &:hover {
        &::before,
        &::after {
          @include mixin.responsive("md") {
            background-color: var(--color-black-dark);
          }
        }
      }
    }
    &--news {
      text-transform: uppercase;
      @include mixin.responsive("md-max") {
        width: calc(280 * (100vw / #{$calcSp}));
      }
    }
    &--insta {
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(90 * (100vw / #{$calcSp}));
        margin: auto;
        width: calc(12 * (100vw / #{$calcSp}));
        height: calc(12 * (100vw / #{$calcSp}));
        background-image: url(../../assets/img/common/tab_icon_2.svg);
        background-size: contain;
        background-repeat: no-repeat;
        @include mixin.responsive("md") {
          right: calc(44 * (100vw / #{$calcPc}));
          width: calc(6 * (100vw / #{$calcPc}));
          height: calc(6 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          right: 44px;
          width: 6px;
          height: 6px;
        }
      }
      .contents__btn {
        text-indent: -.75em;
      }
      &:hover {
        &::after {
          @include mixin.responsive("md") {
            background-image: url(../../assets/img/common/tab_icon.svg);
          }
        }
      }
    }
  }
}

/**
 * core-contents__philosophy （フィロソフィー）
 */
.home-contents {
  .core-contents {
    &__philosophy {
      padding: calc(86 * (100vw / #{$calcSp})) 0 calc(43 * (100vw / #{$calcSp}));
      @include mixin.responsive("md") {
        padding: calc(90 * (100vw / #{$calcPc})) 0 calc(40 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        padding: 90px 0 40px;
      }
      &--lpfg {
        background-color: var(--color-bg-lpfg);
        .core-contents {
          &__ttl,&__txt {
            color: var(--color-white);
          }
        }
      }
    }
  }
}

/**
 * core-contents__history （ヒストリー／モーダル）
 */
.home-contents {
  .core-contents {
    &__history {
      padding-bottom: calc(110 * (100vw / #{$calcSp}));
      @include mixin.responsive("md") {
        padding-bottom: calc(90 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        padding-bottom: 90px;
      }
      &--lpfg {
        background-color: var(--color-bg-lpfg);
        .core-contents {
          &__ttl {
            color: var(--color-white);
          }
        }
      }
      .core-contents {
        &__ttl {
          @include mixin.responsive("md-max") {
            margin-bottom: calc(20 * (100vw / 750));
          }
        }
      }
    }
  }
  .history__modal {
    &--overlay {
      display: none;
      position: fixed;
      inset: 0;
      margin: auto;
      z-index: 9999;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: var(--color-bg-modal);
      overflow: auto;
      overscroll-behavior: none;
      transition: var(--transition-default);
    }
    &--window {
      position: fixed;
      inset: 0;
      margin: auto;
      width: calc(670 * (100vw / #{$calcSp}));
      height: 82%;
      overflow-y: scroll;
      animation-name: modalopen;
      animation-duration: 1s;
      transition: var(--transition-default);
      @include mixin.responsive("md") {
        width: calc(1400 * (100vw / #{$calcXxl}));
        height: 78vh;
      }
      @include mixin.responsive("xxl") {
        width: 1400px;
      }
    }
    &--outer {
      position: relative;
      padding: calc(120 * (100vw / #{$calcSp})) 0 calc(128 * (100vw / #{$calcSp}));
      background-image: url(../../assets/img/home/bg/history_bg_sp.jpg);
      background-size: 100%;
      background-repeat: no-repeat;
      @include mixin.responsive("md") {
        padding: calc(90 * (100vw / #{$calcPc})) 0 calc(103 * (100vw / #{$calcPc}));
        background-image: url(../../assets/img/home/bg/history_bg_pc.jpg);
        background-size: 1400px;
        background-position: top center;
      }
      @include mixin.responsive("xl") {
        padding: 90px 0 103px;
      }
      .contents__btn--history--close {
        position: absolute;
        top: calc(16 * (100vw / #{$calcSp}));
        right: calc(16 * (100vw / #{$calcSp}));
        width: calc(40 * (100vw / #{$calcSp}));
        height: calc(40 * (100vw / #{$calcSp}));
        text-transform: uppercase;
        cursor: pointer;
        border: none;
        border-radius: 0;
        background-color: transparent;
        background-image: url(../../assets/img/home/history/ico_close_sp.svg);
        background-size: contain;
        background-repeat: no-repeat;
        @include mixin.responsive("md") {
          right: calc(12 * (100vw / #{$calcPc}));
          top: calc(12 * (100vw / #{$calcPc}));
          width: calc(34 * (100vw / #{$calcPc}));
          height: calc(34 * (100vw / #{$calcPc}));
          background-image: url(../../assets/img/home/history/ico_close_pc.svg);
        }
        @include mixin.responsive("xl") {
          top: 12px;
          right: 12px;
          width: 34px;
          height: 34px;
        }
      }
      .core-contents {
        &__ttl {
          margin-bottom: calc(90 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            margin-bottom: calc(42 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 42px;
          }
        }
      }
      .history-contents {
        @include mixin.responsive("md") {
          margin: 0 auto;
          width: calc(913 * (100vw / #{$calcPc}));
          font-size: calc(14 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          width: 913px;
          font-size: 14px;
        }
        &__timeline {
          position: relative;
          margin-bottom: calc(146 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
          margin-bottom: calc(78 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 78px;
          }
          &::before {
            content: "";
            position: absolute;
            bottom: calc(-100 * (100vw / #{$calcSp}));
            left: 0;
            right: 0;
            margin: auto;
            width: 1px;
            height: calc(76 * (100vw / #{$calcSp}));
            background-color: var(--color-history-line);
            @include mixin.responsive("md") {
              bottom: calc(-60 * (100vw / #{$calcPc}));
              height: calc(45 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              bottom: -60px;
              height: 45px;
            }
          }
          &:last-child {
            margin-bottom: 0;
            &::before {
              content: none;
            }
          }
          &--year {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto calc(20 * (100vw / #{$calcSp}));
            width: calc(90 * (100vw / #{$calcSp}));
            height: calc(90 * (100vw / #{$calcSp}));
            font-size: calc(24 * (100vw / #{$calcSp}));
            color: var(--color-white);
            font-family: var(--font-en);
            letter-spacing: 0.15em;
            text-align: center;
            background-color: var(--color-black-dark);
            border-radius: 50%;
            @include mixin.responsive("md") {
              margin-bottom: calc(10 * (100vw / #{$calcPc}));
              width: calc(55 * (100vw / #{$calcPc}));
              height: calc(55 * (100vw / #{$calcPc}));
              font-size: calc(14 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              margin-bottom: 10px;
              width: 55px;
              height: 55px;
              font-size: 14px;
            }
            &--big {
              position: relative;
              width: calc(136 * (100vw / #{$calcSp}));
              height: calc(136 * (100vw / #{$calcSp}));
              line-height: 2.5;
              @include mixin.responsive("md") {
                width: calc(84 * (100vw / #{$calcPc}));
                height: calc(84 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                width: 84px;
                height: 84px;
              }
              &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 1px;
                height: calc(20 * (100vw / #{$calcSp}));
                background-color: var(--color-white);
                @include mixin.responsive("md") {
                  height: calc(13 * (100vw / #{$calcPc}));
                }
                @include mixin.responsive("xl") {
                  height: 13px;
                }
              }
            }
          }
          &--txt {
            font-size: calc(24 * (100vw / #{$calcSp}));
            color: var(--color-black);
            line-height: 1.8;
            text-align: center;
            @include mixin.responsive("md") {
              margin: 0 auto;
              font-size: calc(14 * (100vw / #{$calcPc}));
              letter-spacing: 0;
            }
            @include mixin.responsive("xl") {
              font-size: 14px;
            }
          }
          &--row {
            .history-contents__timeline {
              &--txt {
                @include mixin.responsive("md") {
                  position: absolute;
                  top: calc(4 * (100vw / #{$calcPc}));
                  right: 0;
                  margin-right: calc(19 * (100vw / #{$calcPc}));
                  width: calc(400 * (100vw / #{$calcPc}));
                  text-align: left;
                }
                @include mixin.responsive("xl") {
                  top: 4px;
                  margin-right: 19px;
                  width: 400px;
                }
              }
              &--year {
                &--big {
                  + .history-contents__timeline--txt {
                    @include mixin.responsive("md") {
                      margin-right: 0;
                    }
                  }
                }
              }
            } 
          }
          &--row-reverse {
            .history-contents__timeline {
              &--txt {
                @include mixin.responsive("md") {
                  position: absolute;
                  top: calc(4 * (100vw / #{$calcPc}));
                  left: 0;
                  margin-left: calc(19 * (100vw / #{$calcPc}));
                  width: calc(400 * (100vw / #{$calcPc}));
                  text-align: left;
                }
                @include mixin.responsive("xl") {
                  top: 4px;
                  margin-left: 19px;
                  width: 400px;
                }
              }
              &--year {
                &--big {
                  + .history-contents__timeline--txt {
                    @include mixin.responsive("md") {
                      margin-left: 0;
                    }
                  }
                }
              }
            } 
          }
          &--2 {
            .history-contents__timeline--txt {
              @include mixin.responsive("md") {
                top: calc(-2 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                top: -2px;
              }
            }
            &::before {
              @include mixin.responsive("md") {
                height: calc(39 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 39px;
              }
            }
          }
          &--3,
          &--4 {
            &::before {
              @include mixin.responsive("md") {
                height: calc(44 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 44px;
              }
            }
          }
          &--5 {
            @include mixin.responsive("md") {
              margin-bottom: calc(81 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              margin-bottom: 81px;
            }
            .history-contents__timeline--txt {
              @include mixin.responsive("md") {
                top: calc(-2 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                top: -2px;
              }
            }
            &::before {
              @include mixin.responsive("md") {
                height: calc(41 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 41px;
              }
            }
          }
          &--6 {
            &::before {
              @include mixin.responsive("md") {
                height: calc(38 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 38px;
              }
            }
          }
          &--7 {
            @include mixin.responsive("md") {
              margin-bottom: calc(150 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              margin-bottom: 150px;
            }
            .history-contents__timeline--txt {
              @include mixin.responsive("md") {
                top: 0;
              }
            }
            &::before {
              @include mixin.responsive("md") {
                bottom: calc(-130 * (100vw / #{$calcPc}));
                height: calc(110 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                bottom: -130px;
                height: 110px;
              }
            }
          }
          &--8,
          &--9 {
            @include mixin.responsive("md") {
              margin-bottom: calc(79 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              margin-bottom: 79px;
            }
            &::before {
              @include mixin.responsive("md") {
                height: calc(39 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 39px;
              }
            }
          }
          &--10 {
            &::before {
              @include mixin.responsive("md") {
                height: calc(38 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 38px;
              }
            }
          }
          &--11 {
            @include mixin.responsive("md") {
              margin-bottom: calc(79 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              margin-bottom: 79px;
            }
            .history-contents__timeline--txt {
              @include mixin.responsive("md") {
                top: calc(17 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                top: 17px;
              }
            }
            &::before {
              @include mixin.responsive("md") {
                height: calc(41 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 41px;
              }
            }
          }
          &--12 {
            .history-contents__timeline--txt {
              @include mixin.responsive("md") {
                top: calc(-2 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                top: -2px;
              }
            }
            &::before {
              @include mixin.responsive("md") {
                height: calc(38 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 38px;
              }
            }
          }
          &--13 {
            &::before {
              @include mixin.responsive("md") {
                height: calc(38 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 38px;
              }
            }
          }
          &--14 {
            &::before {
              @include mixin.responsive("md") {
                height: calc(39 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 39px;
              }
            }
          }
          &--15 {
            @include mixin.responsive("md") {
              margin-bottom: calc(80 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              margin-bottom: 80px;
            }
            .history-contents__timeline--txt {
              @include mixin.responsive("md") {
                top: calc(17 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                top: 17px;
              }
            }
            &::before {
              @include mixin.responsive("md") {
                height: calc(40 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 40px;
              }
            }
          }
          &--16 {
            @include mixin.responsive("md") {
              margin-bottom: calc(79 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              margin-bottom: 79px;
            }
            .history-contents__timeline--txt {
              @include mixin.responsive("md") {
                top: calc(15 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                top: 15px;
              }
            }
            &::before {
              @include mixin.responsive("md") {
                height: calc(39 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 39px;
              }
            }
          }
          &--17 {
            &::before {
              @include mixin.responsive("md") {
                height: calc(38 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
@keyframes modalopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/**
 * core-contents__collection （コレクション）
 */
 .home-contents {
  .core-contents {
    &__collection {
      padding: calc(93 * (100vw / #{$calcSp})) 0 calc(114 * (100vw / #{$calcSp}));
      background-color: var(--color-white);
      @include mixin.responsive("md") {
        padding: calc(96 * (100vw / #{$calcPc})) 0 calc(100 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        padding: 96px 0 100px;
      }
      .core-contents {
        &__ttl {
          margin-bottom: calc(46 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            margin-bottom: calc(42 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 42px;
          }
        }
      }
      .collection__list {
        display: flex;
        flex-direction: column;
        gap: calc(54 * (100vw / #{$calcSp}));
        margin: 0 auto;
        padding: calc(60 * (100vw / #{$calcSp})) 0;
        width: calc(670 * (100vw / #{$calcSp}));
        background-color: var(--color-bg-light);
        font-family: var(--font-en);
        font-size: calc(24 * (100vw / #{$calcSp}));
        text-align: center;
        line-height: 1;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        @include mixin.responsive("md") {
          flex-direction: row;
          justify-content: center;
          gap: calc(60 * (100vw / #{$calcPc}));
          padding: calc(33 * (100vw / #{$calcPc})) 0 calc(32 * (100vw / #{$calcPc}));
          font-size: calc(14 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          gap: 60px;
          padding: 33px 0 32px;
          font-size: 14px;
        }

        a {
          text-decoration-line: underline;
          transition: var(--transition-default);
          &:hover {
            opacity: var(--hover-opacity);
          }
        }
      }
    }
  }
}

/**
 * core-contents__common--online-store （オンラインストア）
 */
 .home-contents {
  .core-contents__common {
    &--online-store {
      padding: calc(100 * (100vw / #{$calcSp})) 0 calc(112 * (100vw / #{$calcSp}));
      background-color: var(--color-bg-blue);
      @include mixin.responsive("md") {
        padding: calc(100 * (100vw / #{$calcPc})) 0 calc(90 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        padding: 100px 0 90px;
      }
      .core-contents {
        &__ttl {
          margin-bottom: calc(48 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            margin-bottom: calc(40 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 40px;
          }
        }
      }
      .online-store {
        &__logo {
          margin: 0 auto calc(30 * (100vw / #{$calcSp}));
          width: calc(240 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            margin-bottom: calc(20 * (100vw / #{$calcPc}));
            width: calc(157 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 20px;
            width: 157px;
          } 
        }
        &__list {
          margin: 0 auto;
          width: calc(670 * (100vw / #{$calcSp}));
          box-shadow: 0px calc(2 * (100vw / #{$calcSp})) calc(8 * (100vw / #{$calcSp})) var(--color-bg-btn);
          background-color: var(--color-white);
          @include mixin.responsive("md") {
            width: calc(336 * (100vw / #{$calcPc}));
            box-shadow: 0px calc(1 * (100vw / #{$calcPc})) calc(4 * (100vw / #{$calcPc})) var(--color-bg-btn);
          }
          @include mixin.responsive("xl") {
            width: 336px;
            box-shadow: 0px 1px 4px var(--color-bg-btn);
          }
          &--item {
            position: relative;
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              right: calc(190 * (100vw / #{$calcSp}));
              margin: auto;
              width: calc(12 * (100vw / #{$calcSp}));
              height: calc(12 * (100vw / #{$calcSp}));
              background-image: url(../../assets/img/common/tab_icon.svg);
              background-size: contain;
              background-repeat: no-repeat;
              @include mixin.responsive("md") {
                right: calc(84 * (100vw / #{$calcPc}));
                width: calc(6 * (100vw / #{$calcPc}));
                height: calc(6 * (100vw / #{$calcPc}));
                transition: var(--transition-default);
              }
              @include mixin.responsive("xl") {
                right: 84px;
                width: 6px;
                height: 6px;
              }
            }
            &:hover {
              &::after {
                @include mixin.responsive("md") {
                  background-image: url(../../assets/img/common/tab_icon_2.svg);
                }
              }
            }
            &:not(:last-child) {
              .online-store__list--link {
                &::before {
                  content: "";
                  display: block;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                  width: calc(590 * (100vw / #{$calcSp}));
                  height: 1px;
                  background-color: var(--color-bg-light-little);
                  @include mixin.responsive("md") {
                    transition: var(--transition-default);
                    width: calc(296 * (100vw / #{$calcPc}));
                  }
                  @include mixin.responsive("xl") {
                    width: 296px;
                  }
                }
                &:hover {
                  &::before {
                    @include mixin.responsive("md") {
                      background-color: var(--color-main);
                    }
                  }
                }
              }
            }
          }
          &--link {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: calc(9 * (100vw / #{$calcSp})) 0;
            @include mixin.responsive("md") {
              padding: calc(8 * (100vw / #{$calcPc})) 0;
              transition: var(--transition-default);
            }
            @include mixin.responsive("xl") {
              padding: 8px 0;
            }
            &:hover {
              @include mixin.responsive("md") {
                background-color: var(--color-main);
              }
              .online-store__list--logo {
                @include mixin.responsive("md") {
                  opacity: 0;
                }
                &--hover {
                  @include mixin.responsive("md") {
                    opacity: 1;
                  }
                }
              }
            }
          }
          &--logo {
            width: calc(240 * (100vw / #{$calcSp}));
            @include mixin.responsive("md") {
              margin-right: calc(20 * (100vw / #{$calcPc}));
              width: calc(159 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              margin-right: 20px;
              width: 159px;
              transition: var(--transition-default);
            }
            &--hover {
              position: absolute;
              margin-right: 20px;
              width: 159px;
              opacity: 0;
              transition: var(--transition-default);
            }
          }
        }
      }
    }
  }
}
/**
 * core-contents__common--news （ニュース）
 */
.home-contents {
  .core-contents__common {
    &--news {
      padding: calc(94 * (100vw / #{$calcSp})) 0 calc(100 * (100vw / #{$calcSp}));
      background-image: url(../../assets/img/home/bg/news_bg_sp.jpg);
      background-size: contain;
      background-repeat: no-repeat;
      background-repeat: repeat;
      @include mixin.responsive("md") {
        padding: calc(66 * (100vw / #{$calcPc})) 0 calc(90 * (100vw / #{$calcPc}));
        background-image: url(../../assets/img/home/bg/news_bg_pc.jpg);
        background-size: 1920px;
        background-position: top center;
      }
      @include mixin.responsive("xl") {
        padding: 66px 0 90px;
      }
      .core-contents {
        &__ttl {
          margin-bottom: calc(50 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            margin-bottom: calc(72 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 72px;
          }
        }
      }
      .news__list {
        margin: 0 auto calc(60 * (100vw / #{$calcSp}));
        width: calc(670 * (100vw / #{$calcSp}));
        @include mixin.responsive("md") {
          margin-bottom: calc(50 * (100vw / #{$calcPc}));
          width: calc(1040 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          margin-bottom: 50px;
          width: 1040px;
        }
        &--item {
          &:not(:last-child) {
            margin-bottom: calc(30 * (100vw / #{$calcSp}));
            @include mixin.responsive("md") {
              margin-bottom: calc(15 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              margin-bottom: 15px;
            }
          }
        }
        &--link {
          display: flex;
          width: 100%;
          @include mixin.responsive("md") {
            gap: calc(10 * (100vw / #{$calcPc}));
            transition: var(--transition-default);
          }
          @include mixin.responsive("xl") {
            gap: 10px;
          }
          &:hover {
            @include mixin.responsive("md") {
              opacity: var(--hover-opacity);
            }
          }
        }
        &--thumb {
          display: flex;
          align-items: center;
          width: calc(222 * (100vw / #{$calcSp}));
          background-color: var(--color-alpha);
          @include mixin.responsive("md") {
            width: calc(180 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            width: 180px;
          }
        }
        &--txt  {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: calc(28 * (100vw / #{$calcSp})) calc(20 * (100vw / #{$calcSp})) calc(24 * (100vw / #{$calcSp}));
          width: calc(450 * (100vw / #{$calcSp}));
          background-color: var(--color-alpha);
          @include mixin.responsive("md") {
            padding: calc(15 * (100vw / #{$calcPc})) calc(35 * (100vw / #{$calcPc}));
            width: calc(850 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            padding: 15px 35px;
            width: 850px;
          }
        }
        &--date {
          margin-bottom: 6px;
          font-size: calc(22 * (100vw / #{$calcSp}));
          letter-spacing: .15em;
          line-height: 1;
          @include mixin.responsive("md") {
            font-size: calc(13 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            font-size: 13px;
          }
        }
        &--ttl {
          font-size: calc(24 * (100vw / #{$calcSp}));
          letter-spacing: .09em;
          line-height: 1.25;
          @include mixin.responsive("md") {
            font-size: calc(15 * (100vw / #{$calcPc}));
            letter-spacing: .15em;
          }
          @include mixin.responsive("xl") {
            font-size: 15px;
          }
        }
      }
    }
  }
}
/**
 * core-contents__common--shoplist （ショップリスト）
 */
.home-contents {
  .core-contents__common {
    &--shoplist {
      padding: calc(94 * (100vw / #{$calcSp})) 0 calc(110 * (100vw / #{$calcSp}));
      @include mixin.responsive("md") {
        padding: calc(96 * (100vw / #{$calcPc})) 0 calc(100 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        padding: 96px 0 100px;
      }
      .core-contents {
        &__ttl {
          margin-bottom: calc(48 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            margin-bottom: calc(42 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 42px;
          }
        }
      }
      .shoplist__info {
        margin: 0 auto;
        width: calc(670 * (100vw / #{$calcSp}));
        @include mixin.responsive("md") {
          width: calc(1000 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          width: 1000px;
        }
        &--list {
          @include mixin.responsive("md") {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
          }
          @include mixin.responsive("xl") {
          }
          &:nth-of-type(2n) {
            background-color: var(--color-bg-light-little);
          }
          // グレーの網掛けの設定
          // &:nth-of-type(2) {
          //   position: relative;
          //   &::before {
          //     content: "";
          //     position: absolute;
          //     width: 100%;
          //     height: 100%;
          //     display: block;
          //     background: rgb(64 64 64 / 67%);
          //     mix-blend-mode: multiply;
          //     z-index: 1;
          //   }
          //   &::after {
          //     content: "RENEWAL OPEN\Ain February 2024";
          //     position: absolute;
          //     z-index: 10;
          //     right: 0;
          //     color: #fff;
          //     margin: auto;
          //     top: 0;
          //     white-space: pre;
          //     text-align: center;
          //     font-family: var(--font-en);
          //     line-height: 1;
          //     letter-spacing: .1em;
          //     font-size: calc(60 * (100vw / #{$calcSp}));
          //     width: 100%;
          //     height: calc(408 * (100vw / #{$calcSp}));
          //     display: flex;
          //     align-items: center;
          //     justify-content: center;
          //     @include mixin.responsive("md") {
          //       font-size: calc(36 * (100vw / #{$calcPc}));
          //       width: calc(500 * (100vw / #{$calcPc}));
          //       height: fit-content;
          //       bottom: 0;
          //     }
          //     @include mixin.responsive("xl") {
          //       font-size: 36px;
          //       width: 500px;
          //     }
          //   }
          // }
          &--noimage {
            flex-direction: row;
            @include mixin.responsive("md") {
              height: calc(305 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              height: 305px;
            }
          }
          &.pickup {
            border: calc(8 * (100vw / #{$calcSp})) solid var(--color-red);
            @include mixin.responsive("md") {
              border: calc(4 * (100vw / #{$calcPc})) solid var(--color-red);
            }
            @include mixin.responsive("xl") {
              border:4px solid var(--color-red);
            }
          }
        }
        &--img {
          @include mixin.responsive("md") {
            width: calc(500 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            width: 500px;
          }
        }
        &--txt {
          padding: calc(60 * (100vw / #{$calcSp})) calc(28 * (100vw / #{$calcSp})) calc(60 * (100vw / #{$calcSp})) calc(26 * (100vw / #{$calcSp}));
          width: 100%;
          @include mixin.responsive("md") {
            padding: 0 calc(31 * (100vw / #{$calcPc}));
            margin-top: calc(8 * (100vw / #{$calcPc}));
            width: calc(500 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            padding: 0 31px;
            margin-top: 8px;
            width: 500px;
          }
        }
        &--name {
          margin-bottom: calc(20 * (100vw / #{$calcSp}));
          font-size: calc(32 * (100vw / #{$calcSp}));
          font-weight: bold;
          line-height: 1;
          @include mixin.responsive("md") {
            margin-bottom: calc(20 * (100vw / #{$calcPc}));
            font-size: calc(16 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 20px;
            font-size: 16px;
          }
          small {
            display: block;
            margin-top: 0.75em;
          }
          .date {
            display: block;
            margin-top: 0.75em;
            font-size: 95%;
            color: var(--color-red);
            @include mixin.responsive("md") {
              display: inline-block;
              margin-left: 1em;
            }
          }
        }
        &--tag {
          display: flex;
          padding-bottom: calc(20 * (100vw / #{$calcSp}));
          margin-bottom: calc(30 * (100vw / #{$calcSp}));
          font-size: calc(22 * (100vw / #{$calcSp}));
          letter-spacing: .15em;
          line-height: 1;
          font-family: var(--font-en);
          border-bottom: 1px solid var(--color-main);
          @include mixin.responsive("md") {
            padding-bottom: calc(10 * (100vw / #{$calcPcMax}));
            margin-bottom: calc(16 * (100vw / #{$calcPc}));
            font-size: calc(13 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            padding-bottom: 10px;
            margin-bottom: 16px;
            font-size: 13px;
          }
          .tag {
            padding: calc(21 * (100vw / #{$calcSp})) 0 calc(19 * (100vw / #{$calcSp}));
            font-size: calc(24 * (100vw / #{$calcSp}));
            letter-spacing: .15em;
            line-height: 1;
            text-align: center;
            @include mixin.responsive("md") {
              padding: calc(11 * (100vw / #{$calcPc})) 0 calc(12 * (100vw / #{$calcPc}));
              font-size: calc(13 * (100vw / #{$calcPc}));
            }
            @include mixin.responsive("xl") {
              padding: 11px 0 12px;
              font-size: 13px;
            }
            &--lucien {
              width: calc(292 * (100vw / #{$calcSp}));
              background-color: var(--color-gray-light-little);
              color: var(--color-gray-middle);
              @include mixin.responsive("md") {
                width: calc(170 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                width: 170px;
              }
            }
            &--lpfg {
              width: calc(108 * (100vw / #{$calcSp}));
              background-color: var(--color-gray-dark);
              color: var(--color-gray-light-middle);
              @include mixin.responsive("md") {
                width: calc(65 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                width: 65px;
              }
              .red {
                color: var(--color-red);
              }
            }
            &--paris {
              width: calc(176 * (100vw / #{$calcSp}));
              background-color: var(--color-gray-dark);
              color: var(--color-white);
              @include mixin.responsive("md") {
                width: calc(104 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                width: 104px;
              }
            }
            &:not(:last-child) {
              margin-right: calc(20 * (100vw / #{$calcSp}));
              @include mixin.responsive("md") {
                margin-right: calc(7 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                margin-right: 7px;
              }
            }
          }
        }
        &--detail {
          margin-bottom: 6px;
          font-size: calc(28 * (100vw / #{$calcSp}));
          line-height: 1.57;
          display: flex;
          flex-wrap: wrap;
          gap: calc(25 * (100vw / #{$calcSp})) calc(50 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            gap: calc(14 * (100vw / #{$calcPc})) calc(26 * (100vw / #{$calcPc}));
            font-size: calc(14 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            gap: 14px 26px;
            font-size: 14px;
          }
          .only-pc {
            @include mixin.responsive("md") {
              display: inline !important;
            }
          }
          .shoplist__info {
            &--item {
              position: relative;
              margin-left: calc(40 * (100vw / #{$calcSp}));
              @include mixin.responsive("md") {
                margin-left: calc(20 * (100vw / #{$calcPc}));
              }
              @include mixin.responsive("xl") {
                margin-left: 20px;
              }
              &::before {
                content: "";
                display: block;
                position: absolute;
                top: calc(6 * (100vw / #{$calcSp}));
                left: calc(-40 * (100vw / #{$calcSp}));
                margin: auto;
                width: calc(30 * (100vw / #{$calcSp}));
                height: calc(30 * (100vw / #{$calcSp}));
                @include mixin.responsive("md") {
                  top: calc(3 * (100vw / #{$calcPc}));
                  left: calc(-20 * (100vw / #{$calcPc}));
                  width: calc(15 * (100vw / #{$calcPc}));
                  height: calc(15 * (100vw / #{$calcPc}));
                }
                @include mixin.responsive("xl") {
                  top: 3px;
                  left: -20px;
                  width: 15px;
                  height: 15px;
                }
              }
            }
            &--tel {
              &::before {
                background-image: url(../../assets/img/home/shoplist/icon/ico_tel.png);
                background-size: contain;
                background-repeat: no-repeat;
              }
              a {
                @include mixin.responsive("md-max") {
                  text-decoration-line: underline;
                }
                @include mixin.responsive("md") {
                  pointer-events: none
                }
              }
            }
            &--time {
              &::before {
                background-image: url(../../assets/img/home/shoplist/icon/ico_time.png);
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
            &--close {
              &::before {
                background-image: url(../../assets/img/home/shoplist/icon/ico_close.png);
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
            &--address {
              width: 100%;
              &::before {
                top: calc(20 * (100vw / #{$calcSp}));
                height: calc(40 * (100vw / #{$calcSp}));
                background-image: url(../../assets/img/home/shoplist/icon/ico_address.png);
                background-size: contain;
                background-repeat: no-repeat;
                @include mixin.responsive("md") {
                  top: calc(10 * (100vw / #{$calcPc}));
                  width: calc(15 * (100vw / #{$calcPc}));
                  height: calc(20 * (100vw / #{$calcPc}));
                }
                @include mixin.responsive("xl") {
                  top: 10px;
                  width: 15px;
                  height: 20px;
                }
              }
              .google-map {
                display: inline-block;
                margin-top: calc(8 * (100vw / #{$calcSp}));
                padding: calc(10 * (100vw / #{$calcSp})) calc(36 * (100vw / #{$calcSp}));
                line-height: 1;
                border: 1px solid var(--color-dark);
                border-radius: calc(6 * (100vw / #{$calcSp}));
                font-size: calc(24 * (100vw / #{$calcSp}));
                transition: var(--transition-default);
                @include mixin.responsive("md") {
                  margin-top: calc(5 * (100vw / #{$calcPc}));
                  padding: calc(5 * (100vw / #{$calcPc})) calc(18 * (100vw / #{$calcPc}));
                  border-radius: calc(3 * (100vw / #{$calcPc}));
                  font-size: calc(12 * (100vw / #{$calcPc}));
                }
                @include mixin.responsive("xl") {
                  margin-top: 5px;
                  padding: 5px 18px;
                  border-radius: 3px;
                  font-size: 12px;
                }
                &:hover {
                  @include mixin.responsive("md") {
                    color: var(--color-white);
                    background-color: var(--color-dark);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/**
 * core-contents__common--instagram （インスタグラム）
 */
.home-contents {
  .core-contents__common {
    &--instagram {
      padding: calc(100 * (100vw / #{$calcSp})) 0;
      background-color: var(--color-bg-light-middle);
      @include mixin.responsive("md") {
        padding: calc(100 * (100vw / #{$calcPc})) 0 calc(90 * (100vw / #{$calcPc}));
      }
      @include mixin.responsive("xl") {
        padding: 100px 0 90px;
      }
      .core-contents {
        &__ttl {
          margin-bottom: calc(48 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            margin-bottom: calc(42 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            margin-bottom: 42px;
          }
        }
      }
      .instagram__list {
        display: flex;
        justify-content: space-between;
        margin-bottom: calc(60 * (100vw / #{$calcSp}));
        @include mixin.responsive("md") {
          justify-content: center;
          gap: calc(25 * (100vw / #{$calcPc}));
          margin-bottom: calc(50 * (100vw / #{$calcPc}));
        }
        @include mixin.responsive("xl") {
          gap: 25px;
          margin-bottom: 50px;
        }
        &--item {
          width: calc(242 * (100vw / #{$calcSp}));
          @include mixin.responsive("md") {
            width: calc(330 * (100vw / #{$calcPc}));
          }
          @include mixin.responsive("xl") {
            width: 330px;
          }
        }
      }
    }
  }
}